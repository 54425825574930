import {CloudArrowUpIcon, LockClosedIcon, ServerIcon} from "@heroicons/react/24/outline";

export default function Features() {
    return <div className="bg-white py-12 sm:py-24" id="features">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:text-center">
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    Feel empowered, stay connected...
                </p>
                <p className="mt-6 text-lg leading-8 text-gray-600 mb-6">
                    As a shareholder of a property development, life isn't always plain sailing. Votria's comprehensive features
                    not only keep you informed of what's happening, but give you a voice when you need it.
                </p>
            </div>

            <div className="overflow-hidden bg-white py-12 sm:py-12">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start">
                        <div className="lg:pr-4 lg:pt-4">
                            <div className="lg:max-w-lg">
                                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Posts</p>
                                <p className="mt-6 text-lg leading-8 text-gray-600">
                                    Communicate with directors, shareholders, tenants, and even managing agents. Stay informed of what's happening in your development, and get notified of any company announcements.
                                </p>
                            </div>
                        </div>
                        <div className={"m-auto"}>
                            <img
                                src="/posts.png"
                                alt="Posts screenshot"
                                className="m-auto w-[24rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[36rem] md:-ml-4 lg:ml-0"
                                width={2432}
                                height={1442}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="overflow-hidden bg-white py-12 sm:py-12">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                        <div className="lg:ml-auto lg:pl-4 lg:pt-4">
                            <div className="lg:max-w-lg">
                                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Complaints</p>
                                <p className="mt-6 text-lg leading-8 text-gray-600">
                                    Noisy neighbours keeping you awake at night? The anonymous complaint system lets you air your grievances as delicately as possible.
                                </p>
                            </div>
                        </div>
                        <div className="flex items-start justify-end lg:order-first">
                            <div className={"m-auto"}>
                            <img
                                src="/complaints.png"
                                alt="Complaints screenshot"
                                className="w-[24rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[36rem]"
                                width={2432}
                                height={1442}
                            />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="overflow-hidden bg-white py-12 sm:py-12">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start">
                        <div className="lg:pr-4 lg:pt-4">
                            <div className="lg:max-w-lg">
                                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Finances</p>
                                <p className="mt-6 text-lg leading-8 text-gray-600">
                                    Show me the money! Directors can connect the company bank account to Votria, to give shareholders an insight into company finances.
                                </p>
                            </div>
                        </div>
                        <div className={"m-auto"}>
                            <img
                                src="/finances.png"
                                alt="Finances screenshot"
                                className="w-[24rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[36rem] md:-ml-4 lg:ml-0 "
                                width={2432}
                                height={1442}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="mx-auto max-w-2xl lg:text-center mt-12">
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    ...and more!
                </p>
            </div>
        </div>
    </div>
}