import { CheckIcon } from '@heroicons/react/20/solid'

const tiers = [
    {
        name: 'Annual',
        id: 'tier-annual',
        href: '#',
        priceMonthly: '£0.79',
        description: 'Save almost 20% with an annual subscription!',
        features: ["Posts", "Announcements", "Cases", "Complaints", "Finances", "Meetings", "Free tenant access", "Ad-free", "No tracking"],
        mostPopular: true,
        trial: false,
    },
    {
        name: 'Monthly',
        id: 'tier-monthly',
        href: '#',
        priceMonthly: '£0.99',
        description: 'Cautiously optimistic? This is the plan for you!',
        features: ["Posts", "Announcements", "Cases", "Complaints", "Finances", "Meetings", "Free tenant access", "Ad-free", "No tracking"],
        mostPopular: false,
        trial: false,
    },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Pricing() {
    return (
        <div className="bg-white py-12 sm:py-16" id="pricing">
            <div className="mx-auto max-w-3xl px-6 lg:px-8">
                <div className="mx-auto max-w-4xl text-center">
                    <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                        Affordable plans for companies of all sizes
                    </p>
                </div>
                <div className="isolate mx-auto mt-16 grid max-w-md grid-cols-1 gap-y-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                    {tiers.map((tier, tierIdx) => (
                        <div
                            key={tier.id}
                            className={classNames(
                                tier.mostPopular ? 'lg:z-10 lg:rounded-b-none' : 'lg:mt-8',
                                tierIdx === 0 ? 'lg:rounded-r-none' : '',
                                tierIdx === tiers.length - 1 ? 'lg:rounded-l-none' : '',
                                'flex flex-col justify-between rounded-3xl bg-white p-8 ring-1 ring-gray-200 xl:p-10'
                            )}
                        >
                            <div>
                                <div className="flex items-center justify-between gap-x-4">
                                    <h3
                                        id={tier.id}
                                        className={classNames(
                                            tier.mostPopular ? 'text-indigo-600' : 'text-gray-900',
                                            'text-lg font-semibold leading-8'
                                        )}
                                    >
                                        {tier.name}
                                    </h3>
                                    {tier.mostPopular ? (
                                        <p className="rounded-full bg-indigo-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-indigo-600">
                                            Most popular
                                        </p>
                                    ) : null}
                                </div>
                                <p className="mt-4 text-sm leading-6 text-gray-600">{tier.description}</p>
                                <p className="mt-6 flex items-baseline gap-x-1">
                                    <span className="text-4xl font-bold tracking-tight text-gray-900">{tier.priceMonthly}</span>

                                    {tier.trial ?
                                        <></>
                                        :
                                        <span className="text-sm font-semibold leading-6 text-gray-600"> / licensed user / month</span>
                                    }
                                </p>
                                <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                                    {tier.features.map((feature) => (
                                        <li key={feature} className="flex gap-x-3">
                                            <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
                                            {feature}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
