import { useState, useRef } from 'react'
import ReCAPTCHA from 'react-google-recaptcha';
import {verifyToken} from "../../services/register";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Signup() {
    const [name, setName] = useState("")
    const [address, setAddress] = useState("")

    const [error, setError] = useState("")
    const [message, setMessage] = useState("")

    const captchaRef = useRef(null);

    const handleSubmit = async e => {
        e.preventDefault();
        setError('');
        setMessage('');
        if(name && address){
            let token = captchaRef.current.getValue();
            if(token){
                try {
                    const {status, body} = await verifyToken({
                        token: token,
                        name: name,
                        address: address,
                    });

                    if (status === 200) {
                        localStorage.setItem("sent", "yes")
                        setMessage("Thanks, we'll be in touch!")
                    } else {
                        setError("Unable to verify token")
                    }
                } catch (error) {
                    setError("Unable to verify token")
                }


            }else{
                setError("You must confirm you are not a robot");
            }
        }else{
            setError("Name and address are required");
        }
    }

    return (
        <div className="isolate bg-white px-6 py-12 sm:py-24 lg:px-8" id="signup">

            <div className="mx-auto max-w-2xl text-center">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-12">Request access</h2>
                <p className="mt-2 text-lg leading-8 text-gray-600">
                    Votria is currently invite-only, but we'd love to get you onboarded as soon as we can! Give us some information about your property management company; if you can provide the postcodes of one or more properties managed by the company, we'll do the rest!
                </p>
            </div>
            {localStorage.getItem("sent") !== "yes" ?

                <form onSubmit={handleSubmit} className="mx-auto mt-8 max-w-xl sm:mt-12">
                    <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                        <div className="sm:col-span-2">
                            <label htmlFor="name" className="block text-sm font-semibold leading-6 text-gray-900">
                                Management company name
                            </label>
                            <div className="mt-2.5">
                                <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="address" className="block text-sm font-semibold leading-6 text-gray-900">
		    		Postcode(s) of properties managed by this company 
                            </label>
                            <div className="mt-2.5">
                        <input
                            name="address"
		    	    type="text"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} ref={captchaRef}/>
                        </div>
                    </div>
                    <div className="mt-10">
                        <button
                            className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Request access
                        </button>
                    </div>
                </form>
                :
                <div className="mx-auto max-w-2xl text-center">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-12 mt-12">
                        Thanks, we'll be in touch!
                    </h2>
                </div>
            }
        </div>
    )
}
