
export function verifyToken(tokenDetails) {
    return fetch(`${process.env.REACT_APP_API}/v1/request-access`, {
        method: 'POST',
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(tokenDetails),
    }).then(r => r.json().then(data => ({status: r.status, body: data})))
}