const navigation = {
    solutions: [
    ],
    support: [
    ],
    company: [
    ],
    legal: [
    ],
    social: [],
}

export default function Footer() {
    return (
        <footer className="bg-gray-900" aria-labelledby="footer-heading">
            <div className="pt-12 content-center justify-items-center text-center">
            </div>
            <div className="mx-auto max-w-7xl px-6 pb-8 pt-12">
                <p className="text-xs leading-5 text-gray-400 text-center content-center m-auto">&copy; {(new Date().getFullYear())} STDIN LTD. All rights reserved.</p>
            </div>
        </footer>
    )
}
