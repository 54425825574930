import './App.css';

import Header from "../Header/Header";
import Features from "../Features/Features";
import Signup from "../Signup/Signup";
import Pricing from "../Pricing/Pricing";
import Footer from "../Footer/Footer";
import FAQ from "../FAQ/FAQ";
import Product from "../Product/Product";
import Terms from "../Terms/Terms";
import Privacy from "../Privacy/Privacy";

function App() {
  if (window.location.pathname === "/terms") {
    return <Terms/>
  } else if (window.location.pathname === "/privacy") {
    return <Privacy/>
  }

  return <>
    <Header/>
    <Product/>
    <Features/>
    <Pricing/>
    <FAQ/>
    <Signup/>
    <Footer/>
  </>
}

export default App;